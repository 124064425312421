<template>
  <div class="detail-info-content" v-if="registerInfo">
    <div class="content_one">
      <div class="content-title" style="font-size: 16px">流调登记信息</div>
      <el-row>
        <DetailItem
          left-label="任务来源"
          :right-value="registerInfo.survey.taskSource || '-'"
        />
        <DetailItem left-label="调查人员" :right-value="dcry" />
        <DetailItem
          left-label="事件名称"
          :right-value="registerInfo.survey.eventName || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="调查日期"
          :right-value="registerInfo.survey.surveyDate || '-'"
        />

        <DetailItem
          v-if="registerInfo.survey.surveyResult == '其他'"
          left-label="流调结果"
          :right-value="
            `${registerInfo.survey.surveyResult}（${registerInfo.survey.otherResult}）` ||
            '-'
          "
        />
        <DetailItem
          v-else
          left-label="流调结果"
          :right-value="`${registerInfo.survey.surveyResult}` || '-'"
        />
        <DetailItem
          left-label="调查地点"
          :right-value="registerInfo.survey.surveyAddress || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="任务说明"
          :right-value="registerInfo.survey.taskRemark || '-'"
        />
      </el-row>
      
      <el-row>
        
        <div class="fss">
          <div style="font-size: 15px; color: #666; margin-left: 80px">
            附件：
          </div>

          <div class="filed">
            <span
              v-for="(i, v) in registerInfo.files"
              :key="v"
              class="filed_span"
              @click="fileddd(i)"
              >{{ i.fileName }}</span
            >
          </div>
        </div>
      </el-row>
    </div>
    <div class="content_one">
      <div class="content-title">调查对象基本信息</div>
      <el-row>
        <DetailItem
          left-label="姓名"
          :right-value="registerInfo.survey.name || '-'"
        />
        <DetailItem
          left-label="性别"
          :right-value="registerInfo.survey.sex == 1 ? '男' : '女' || '-'"
        />
        <DetailItem
          left-label="证件类型"
          :right-value="registerInfo.survey.cardType || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="证件号码"
          :right-value="registerInfo.survey.cardNo || '-'"
        />
        <DetailItem
          left-label="联系电话"
          :right-value="registerInfo.survey.linkPhone || '-'"
        />
        <DetailItem
          left-label="年龄"
          :right-value="registerInfo.survey.age || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="国籍"
          :right-value="registerInfo.survey.country || '-'"
        />
        <DetailItem
          left-label="民族"
          :right-value="registerInfo.survey.nation || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          :spanWidth="24"
          left-label="现住地址"
          :right-value="
            spliceString(
              registerInfo.survey.currentAddress,
              registerInfo.survey.currentAddressDetail
            )
          "
        />
      </el-row>
      <el-row>
        <DetailItem
          v-if="registerInfo.survey.crowdTypeName == '其他人群'"
          left-label="人群分类"
          :right-value="
            `${registerInfo.survey.crowdTypeName}（${registerInfo.survey.otherCrowdTypeName}）` ||
            '-'
          "
        />
        <DetailItem
          v-else
          left-label="人群分类"
          :right-value="
            `${registerInfo.survey.crowdTypeName}${registerInfo.survey.otherCrowdTypeName}` ||
            '-'
          "
        />
        <DetailItem
          left-label="次人群分类"
          :right-value="`${this.secondaryCrowdTypes}` || '-'"
        />
      </el-row>
        <el-row>
          <DetailItem
          left-label="调查对象所属地区"
          :right-value="registerInfo.survey.surveyObjectBelongArea || '-'"
        />
        <DetailItem
          left-label="所属社区"
          :right-value="registerInfo.survey.surveyObjectBelongOrg || '-'"
        />
        </el-row>
        
      
    </div>
    <template v-if="closeContactInfo.length > 0">
      <div>
        <div class="content_one">
          <div class="content-title">关联的密切接触者（一密）信息</div>
          <el-tabs type="border-card">
            <el-tab-pane
              :label="item.relationName"
              v-for="item in closeContactInfo"
              :key="item.id"
            >
              <el-row>
                <DetailItem
                  left-label="姓名"
                  :right-value="item.relationName || '-'"
                />
                <DetailItem
                  left-label="身份证号"
                  :right-value="item.relationCardNo || '-'"
                />
                <DetailItem
                  left-label="所属地区"
                  :right-value="item.relationAddress || '-'"
                />
              </el-row>
              <el-row>
                <DetailItem
                  left-label="关系"
                  :right-value="
                    spliceString(item.relationType, item.relationTypeOther)
                  "
                />
                <DetailItem
                  left-label="最早接触时间"
                  :right-value="item.firstContactTime || '-'"
                />
                <DetailItem
                  left-label="最后接触时间"
                  :right-value="item.lastContactTime || '-'"
                />
              </el-row>
              <el-row>
                <DetailItem
                  left-label="接触频率"
                  :right-value="item.contactFrequencyType || '-'"
                />
                <DetailItem
                  left-label="接触地点"
                  :right-value="item.contactAddress || '-'"
                />
                <DetailItem
                  left-label="接触方式"
                  :right-value="item.contactType || '-'"
                />
              </el-row>
              <el-row>
                <DetailItem
                  left-label="同交通工具填写"
                  :right-value="item.vehicle || '-'"
                />
                <DetailItem
                  left-label="单次暴露时间"
                  :right-value="item.singleExposureTime || '-'"
                />
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </template>
    <template v-if="positiveCaseInfo.length > 0">
      <div>
        <div class="content_one">
          <div class="content-title">关联阳性病例信息</div>
          <el-tabs type="border-card">
            <el-tab-pane
              :label="item.relationName"
              v-for="item in positiveCaseInfo"
              :key="item.id"
            >
              <el-row>
                <DetailItem
                  left-label="姓名"
                  :right-value="item.relationName"
                />
                <DetailItem
                  left-label="身份证号"
                  :right-value="item.relationCardNo"
                />
                <DetailItem
                  left-label="所属地区"
                  :right-value="item.relationAddress || '-'"
                />
              </el-row>
              <el-row>
                <DetailItem
                  left-label="发病/核酸检测阳性日期"
                  :right-value="item.dateOfOnset || '-'"
                />
                <DetailItem
                  left-label="接诊医院/核酸检测阳性单位"
                  :right-value="item.receivingOrg || '-'"
                />
                <DetailItem
                  left-label="病例分类"
                  :right-value="spliceString(item.caseType, item.caseTypeOther)"
                />
              </el-row>
              <el-row>
                <DetailItem
                  left-label="关系"
                  :right-value="
                    spliceString(item.relationType, item.relationTypeOther)
                  "
                />
                <DetailItem
                  left-label="最早接触时间"
                  :right-value="item.firstContactTime || '-'"
                />
                <DetailItem
                  left-label="最后接触时间"
                  :right-value="item.lastContactTime || '-'"
                />
              </el-row>
              <el-row>
                <DetailItem
                  left-label="接触频率"
                  :right-value="item.contactFrequencyType || '-'"
                />
                <DetailItem
                  left-label="接触地点"
                  :right-value="item.contactAddress || '-'"
                />
                <DetailItem
                  left-label="接触方式"
                  :right-value="item.contactType || '-'"
                />
              </el-row>
              <el-row>
                <DetailItem
                  left-label="同交通工具填写"
                  :right-value="item.vehicle || '-'"
                />
                <DetailItem
                  left-label="单次暴露时间"
                  :right-value="item.singleExposureTime || '-'"
                />
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </template>
    <div class="content_one">
      <div class="content-title">调查对象其他信息</div>
      <el-row>
        <DetailItem
          left-label="是否是医务人员"
          :right-value="registerInfo.survey.medicalStaff | threeFilter"
        />
        <DetailItem
          left-label="工作单位"
          :spanWidth="12"
          :right-value="registerInfo.survey.workUnit || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="是否出现临床症状"
          :right-value="registerInfo.survey.clinicalSymptoms | threeFilter"
        />
        <DetailItem
          left-label="首次出现症状日期"
          :right-value="registerInfo.survey.dateOfFirstSymptom || '-'"
        />
        <DetailItem
          left-label="首发症状的表现"
          :right-value="
            spliceString(
              registerInfo.survey.firstSymptom,
              registerInfo.survey.firstSymptomOther
            )
          "
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="是否有基础性疾病"
          :right-value="registerInfo.survey.basicDiseases | threeFilter"
        />
        <DetailItem
          left-label="基础性疾病名称"
          :spanWidth="12"
          :right-value="
            spliceString(
              registerInfo.survey.basicDiseasesName,
              registerInfo.survey.otherBasicDiseasesName
            )
          "
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="是否接种了新冠病毒疫苗"
          :right-value="registerInfo.survey.vaccination || '-'"
        />
        <DetailItem
          left-label="接种了哪种新冠病毒疫苗"
          :right-value="
            spliceString(
              registerInfo.survey.vaccinationType,
              registerInfo.survey.vaccinationTypeOther
            )
          "
        />
        <DetailItem
          left-label="接种了几剂新冠病毒疫苗"
          :right-value="registerInfo.survey.inoculationNum || '-'"
        />
      </el-row>
      <el-row>
        <DetailItem
          left-label="是否完成了新冠病毒疫苗全程接种"
          :right-value="registerInfo.survey.finishVaccination || '-'"
        />
      </el-row>
    </div>
  </div>
</template>

<script>
import DetailItem from "@/views/QuarantineRegistration/modules/DetailItem";
import { Loading } from "element-ui";
import { getSurveyInfoApi } from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import {
  SURVEY_RESULT,
  THREE_SELECT,
  CASE_TYPE,
  RELATION_TYPE,
  CONTACT_FREQUENCY_TYPE,
  CONTACT_ADDRESS,
  CONTACT_TYPE,
} from "@/common/constants";
import constants from "@/common/constants";
export default {
  name: "TransferInfo",
  components: { DetailItem },
  data() {
    return {
      dcry: "",
      cardTypeDict: constants.TYPE_CERTIFICATE,
      registerInfo: {
        survey: {
          linkPhone: "",
        },
        user: {
          linkPhone: "",
        },
      },
      positiveCaseInfo: [],
      closeContactInfo: [],
      secondaryCrowdTypes:''
    };
  },
  filters: {
    threeFilter(val) {
      const value = !!val
        ? THREE_SELECT.find((item) => {
            return item.value == val;
          })
        : "";
      return value ? value.label : "-";
    },
  },
  mounted() {},
  methods: {
    // 流调详情
    async getSurveyInfo(id) {
      const params = { id: id };
      const { data } = await getSurveyInfoApi({ params });
      if (data.code == "200") {
        this.registerInfo = data.data;
        // console.log("🚀 流调详情 ", this.registerInfo);
        let newArr = []
        this.registerInfo.survey.secondaryCrowdTypes.forEach(item=>{
          newArr.push(item.crowdTypeName)
        })
        // console.log(newArr);
        this.secondaryCrowdTypes = newArr.join(' , ')
        if (data.data.taskFiles) {
          for (let i in data.data.taskFiles) {
            this.registerInfo.files.push(data.data.taskFiles[i]);
          }
        }
        if (this.registerInfo.user) {
          this.dcry =
            this.registerInfo.survey.surveyUserName +
              "(" +
              this.registerInfo.user.linkPhone +
              ")" || "-";

          if (this.dcry.includes("undefined")) {
            this.dcry = "";
          }
        } else {
          this.dcry = this.registerInfo.survey.surveyUserName;
        }

        this.positiveCaseInfo = this.registerInfo.surveyRelationList.filter(
          (item) => {
            if (
              item.relationAddressCode ==
              "500000000000,500100000000,500103000000"
            ) {
              item.relationAddress = "重庆市，市辖区，渝中区";
            }
            return item.type == 0;
          }
        );
        this.closeContactInfo = this.registerInfo.surveyRelationList.filter(
          (item) => {
            return item.type == 1;
          }
        );
        this.renderData();
      }
    },
    //处理数据
    renderData() {
      const {
        surveyResult,
        medicalStaff,
        dateOfFirstSymptom,
        vaccination,
        finishVaccination,
        clinicalSymptoms,
        cardType,
        basicDiseases,
      } = this.registerInfo.survey;
      //调查结果
      const surveyResultValue = !!surveyResult
        ? SURVEY_RESULT.find((item) => {
            return item.value == surveyResult;
          })
        : "";
      this.registerInfo.survey.surveyResult = surveyResultValue
        ? surveyResultValue.label
        : "";
      //证件类型
      const cardTypeValue = !!cardType
        ? this.cardTypeDict.find((item) => {
            return item.value == cardType;
          })
        : "";
      this.registerInfo.survey.cardType = cardTypeValue
        ? cardTypeValue.label
        : "";
      //调查时间
      this.registerInfo.survey.surveyDate = this.$moment(
        this.registerInfo.survey.surveyDate
      ).format("YYYY-MM-DD HH:mm");
      //首次检测日期
      this.registerInfo.survey.dateOfFirstSymptom = !!dateOfFirstSymptom
        ? this.$moment(dateOfFirstSymptom).format("YYYY-MM-DD")
        : "";
      //是否接种了新冠病毒疫苗
      this.registerInfo.survey.vaccination =
        vaccination == "1" ? "是" : vaccination ? "否" : "";
      //是否完成了新冠病毒疫苗全程接种
      this.registerInfo.survey.finishVaccination =
        finishVaccination == "1" ? "是" : finishVaccination ? "否" : "";
      if (this.positiveCaseInfo.length > 0) {
        this.positiveCaseInfo = this.positiveCaseInfo.map((item) => {
          //发病/核酸检测阳性日期
          item.dateOfOnset = item.dateOfOnset
            ? this.$moment(item.dateOfOnset).format("YYYY-MM-DD")
            : "";
          //病例分类
          const caseType = item.caseType
            ? CASE_TYPE.find((_item) => {
                return _item.value == item.caseType;
              })
            : "";
          item.caseType = caseType ? caseType.label : "";
          return item;
        });
        this.commonRender(this.positiveCaseInfo);
      }
      if (this.closeContactInfo.length > 0) {
        this.commonRender(this.closeContactInfo);
      }
    },
    //阳性/密接公共数据处理
    commonRender(data) {
      data.map((item) => {
        //关系
        const relationType = item.relationType
          ? RELATION_TYPE.find((_item) => {
              return _item.value == item.relationType;
            })
          : "";
        item.relationType = relationType ? relationType.label : "";
        //接触频率
        const contactFrequencyType = item.contactFrequencyType
          ? CONTACT_FREQUENCY_TYPE.find((_item) => {
              return _item.value == item.contactFrequencyType;
            })
          : "";
        item.contactFrequencyType = contactFrequencyType
          ? contactFrequencyType.label
          : "";
        //接触地点
        const contactAddress = item.contactAddress
          ? CONTACT_ADDRESS.find((_item) => {
              return _item.value == item.contactAddress;
            })
          : "";
        item.contactAddress = contactAddress ? contactAddress.label : "";
        //接触方式
        const contactType = item.contactType
          ? CONTACT_TYPE.find((_item) => {
              return _item.value == item.contactType;
            })
          : "";
        item.contactType = contactType ? contactType.label : "";
        //最早接触时间
        item.firstContactTime = item.firstContactTime
          ? this.$moment(item.firstContactTime).format("YYYY-MM-DD HH:mm")
          : "";
        //最晚接触时间
        item.lastContactTime = item.lastContactTime
          ? this.$moment(item.lastContactTime).format("YYYY-MM-DD HH:mm")
          : "";
        return item;
      });
    },
    //处理多字段拼接
    spliceString(val1, val2) {
      if (!val1 && !val2) {
        return "-";
      } else if (val2 == null) {
        return val1;
      } else {
        return val1 + val2;
      }
    },
    fileddd(i) {
      let fileUrl = i.fileUrl;
      let loadingInstance = Loading.service();
      Loading.service();

      //获取文件后缀
      // const suffix = i.fileName.substring(i.name.lastIndexOf(".") + 1);
      // if (["png", "jpg"].includes(suffix)) {
      let alink = document.createElement("a");
      alink.href = fileUrl;
      alink.target = "_blank";
      alink.download = i.fileName; //fileName保存提示中用作预先填写的文件名
      alink.click();
      // console.log("handlePreview -> alink", alink);
      // } else if (["docx", "doc", "pdf", "xls", "xlsx"].includes(suffix)) {
      // console.log(2);
      // downloadExportFile(fileUrl, i.name, suffix);
      // } else {
      // return;
      // }

      setTimeout(() => {
        loadingInstance.close();
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.content_one {
  margin-top: 10px;

  .content-title {
    padding: 20px 0;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-left: 14px;

    &:after {
      content: "";
      width: 5px;
      height: 23px;
      background: #409eff;
      position: absolute;
      top: 22px;
      left: -12px;
    }
  }
}

.el-row {
  display: flex;
  align-items: center;
}

.fss {
  display: flex;
}

.filed {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.filed_span {
  cursor: pointer;
  margin-bottom: 10px;
}
</style>